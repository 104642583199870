import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Layout from '../components/GatsbyLayout';
import Helmet from '../components/Helmet';
import Subtitle from '../components/Subsection/Subtitle';

import FourOFour from '../assets/images/404.png';

const renderHelmet = () => (
  <Helmet
    title="MCRO | Oops 404"
    description="Best react and Javascript development team in Cluj-Napoca, Romania."
    keywords="Javascript, React.JS, Angular, Sencha, ExtJS, Vue.Js, Node.JS, PHP,
    iOS, Swift, Android, Java, DevOps, QA, Automation, Security"
    thumbnail={FourOFour}
  />
);

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <div id="page_404" className="main">
      {renderHelmet()}
      <div className="inner">
        <Subtitle
          title="Oops!"
          titleType="h1"
          classNames="margin-top"
          withoutLine
        />
        <h2>The link you clicked may be broken or the page may have been removed.</h2>
      </div>
      <img src={FourOFour} alt="FourOFour" title="FourOFour" className="image" />
      <Link to="/" className="back-btn button-secondary font-button-large-bold">
        <i className="fa fa-arrow-circle-left" />
        &nbsp;GO BACK HOME
      </Link>
    </div>
  </Layout>
);

NotFoundPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default NotFoundPage;
