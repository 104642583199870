import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TitleLine from './TitleLine';

const getTitle = (props) => {
  const { title, titleType, specialLayout, titleClassNames, blogTitle } = props;
  let titleElement;

  switch (titleType) {
    case 'h1':
      if (blogTitle) {
        titleElement = (<h1 className={classnames('font-title', titleClassNames)} tabIndex="0">{title}</h1>);
      } else {
        titleElement = (
          <h1 className={classnames('font-mega font-weight-light-bold', titleClassNames)} tabIndex="0">{title}</h1>
        );
      }
      break;
    case 'h2':
      titleElement = (
        <h2 className={classnames('font-display font-weight-light-bold', titleClassNames)} tabIndex="0">{title}</h2>
      );
      break;
    case 'h3':
      titleElement = (
        <h3 className={classnames('font-mega font-weight-bold', titleClassNames)} tabIndex="0">{title}</h3>
      );
      break;
    case 'withDot':
      titleElement = (
        <h2 className={classnames('font-display font-weight-light-bold', titleClassNames)} tabIndex="0">
          {title}
          <span className="dot">.</span>
        </h2>
      );
      break;
    case 'withDotH1':
      titleElement = (
        <h1 className={classnames('font-display font-weight-light-bold', titleClassNames)} tabIndex="0">
          {title}
          <span className="dot">.</span>
        </h1>
      );
      break;
    default:
      titleElement = (
        <h1 className={classnames('font-display font-weight-light-bold', titleClassNames)} tabIndex="0">{title}</h1>
      );
      break;
  }

  if (specialLayout) {
    return specialLayout(titleElement);
  }

  return titleElement;
};

getTitle.propTypes = {
  title: PropTypes.string,
  titleType: PropTypes.string,
  titleClassNames: PropTypes.string,
  specialLayout: PropTypes.func,
};

getTitle.defaultProps = {
  title: null,
  titleType: null,
  titleClassNames: null,
  specialLayout: null,
};

const renderAfterTitle = (afterTitle, afterTitleClassNames) => (
  <h3
    className={classnames('font-weight-normal subsection-subtitle__after-element', afterTitleClassNames)}
    tabIndex="0"
  >
    {afterTitle}
  </h3>
);

const Subtitle = (props) => {
  const { title, beforeTitle, afterTitle, classNames, withoutLine, afterTitleClassNames } = props;

  return (
    <div className={classnames('subsection-subtitle', classNames)}>
      {beforeTitle
        ? <h5 className="font-weight-normal subsection-subtitle__before-element" tabIndex="0">{beforeTitle}</h5>
        : null
        }

      {title && getTitle(props)}

      {withoutLine ? null : <TitleLine />}

      {afterTitle
        ? renderAfterTitle(afterTitle, afterTitleClassNames)
        : null
        }
    </div>
  );
};

// Subtitle could also have:
// titleClassNames, beforeTitleClassNames etc
Subtitle.propTypes = {
  title: PropTypes.string,
  beforeTitle: PropTypes.string,
  classNames: PropTypes.string,
  afterTitle: PropTypes.string,
  afterTitleClassNames: PropTypes.string,
  withoutLine: PropTypes.bool,
};

Subtitle.defaultProps = {
  title: null,
  beforeTitle: null,
  classNames: null,
  afterTitle: null,
  afterTitleClassNames: null,
  withoutLine: false,
};

export default Subtitle;
